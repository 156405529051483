<template>
    <MeasurementItem v-if="isLoading || activities.length">
        <template #content>
            <div v-if="isLoading" class="no-padding l-full-width">
                <VSpinner size="medium" line-fg-color="black" :speed="1" />
            </div>

            <div v-else class="no-padding">
                <h3>{{ $t('title') }}</h3>

                <p v-for="(activity, i) in activities" :key="i">
                    {{ activity }}
                </p>
            </div>
        </template>
    </MeasurementItem>
</template>

<script>
import moment from 'moment'
import VSpinner from 'vue-simple-spinner'

import { formatHelper, httpHelper } from '../utils'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'

export default {
    name: 'TruckTestHistoryMeasurementFragment',
    components: {
        MeasurementItem,
        VSpinner,
    },
    mixins: [MeasurementListHelper],
    data() {
        return {
            activities: [],
            isLoading: true,
        }
    },
    watch: {
        'sensorData.driver_id': {
            immediate: true,
            async handler(newDriverId, oldDriverId) {
                if (newDriverId?.value === oldDriverId?.value) {
                    return
                }
                try {
                    this.isLoading = true
                    const { data } = await httpHelper.getRecursively(
                        '/tachograph/driver-activities/',
                        {
                            params: {
                                driver_id: newDriverId?.value,
                                start: moment()
                                    .startOf('day')
                                    .format(),
                                end: moment()
                                    .endOf('day')
                                    .format(),
                            },
                        }
                    )
                    this.activities = data.results
                        .sort((a, b) => new Date(a.start) - new Date(b.start))
                        .reduce((acc, cur, i) => {
                            if (i === 0) {
                                acc.push(
                                    `${this.$t('start')}: ${this.formatTime(
                                        cur.start
                                    )}`
                                )
                            }
                            if (cur.activity === 'Rest') {
                                acc.push(
                                    `${this.$t('pause')}: ${this.formatTime(
                                        cur.start
                                    )} - ${this.formatTime(
                                        cur.end
                                    )} (${formatHelper.hoursAndMinutesDuration(
                                        (new Date(cur.end) -
                                            new Date(cur.start)) /
                                            1000
                                    )})`
                                )
                            }
                            if (
                                i === data.results.length - 1 &&
                                !this.sensorData.driver_1_card_presence?.value
                            ) {
                                acc.push(
                                    `${this.$t('end')}: ${this.formatTime(
                                        cur.end
                                    )}`
                                )
                            }
                            return acc
                        }, [])
                } finally {
                    this.isLoading = false
                }
            },
        },
    },
    methods: {
        formatTime(time) {
            return moment(time).format('HH:mm')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "end": "End",
        "pause": "Break",
        "start": "Start",
        "title": "Trip history"
    },
    "de": {
        "end": "Ende",
        "pause": "Pause",
        "start": "Start",
        "title": "Zeiterfassung"
    },
    "fr": {
        "end": "Fin",
        "pause": "Pause",
        "start": "Début",
        "title": "Parcours"
    },
    "it": {
        "end": "Fine",
        "pause": "Rottura",
        "start": "Inizio",
        "title": "Percorso di viaggio"
    }
}
</i18n>
